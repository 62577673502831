// TEXTBOX
(() => {
  const textboxes = document.querySelectorAll(
    '.tc_textbox:not([data-processed])',
  );

  textboxes.forEach((textbox) => {
    var textboxGrandParent = textbox.parentNode.parentNode;
    var textboxHeight = textbox.offsetHeight;
    var textboxBodyHeight =
      textbox.querySelector('.tc_textbox__body').offsetHeight;
    var textboxButton;
    var buffer = 150;

    if (textboxBodyHeight > textboxHeight + buffer) {
      textboxButton = textbox.querySelector('.tc_textbox__footer .tc_button');
      textboxButton.addEventListener('click', () => {
        if (textboxGrandParent.getAttribute('data-object-position')) {
          textboxGrandParent.setAttribute('data-object-position', 'default');
        }
        textbox.classList.toggle('js_textbox--open');
      });
      textbox.classList.add('js_textbox--toggle');
    } else {
      textbox.classList.add('js_textbox--notoggle');
    }

    textbox.setAttribute('data-processed', 'true');
  });
})();
